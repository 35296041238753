<template>
  <b-modal id="rick-rolling" hide-footer :size="size" @hide="clearSelection">
    <div class="d-flex justify-content-center">
      <lazy-youtube src="https://www.youtube.com/watch?v=ozl3L9fhKtE" :autoplay="true" />
    </div>
    <br>
    <div class="col-12">
      <p>
        THANK YOU for being a great customer. Our goal is to be the easiest service to cancel when you're ready to go. If you've tried any of our competitors you'll probably agree.
      </p>
      <p>
        That's why we:
      </p>
      <ul>
        <li>Provide options to remove your credit card yourself AND we delete it from our information.</li>
        <li>Allow you to cancel any service without having to call, chat, or email us.</li>
        <li>Never hide the cancellation button.</li>
        <li>Have Customer Service that's easy to find on the front facing website and inside your client portal.</li>
      </ul>
      <p>
        Our hope is that if we make it easy to sign up and easy to cancel, you'll trust us and come back to let us help you again. Or at least that you enjoy a fun song from the 1970s for a couple minutes, find your balance, and be in a good place to head where you want to go.
      </p>
      <p>
        Thank you again for your business, and please come back some day if you think we could be of help.
      </p>
    </div>
  </b-modal>
</template>
<script>

export default {
  name: 'RickRollModal',
  props: {
    bus: null,
  },
  data() {
    return {
      size: 'xl',
    }
  },
  computed: { },
  mounted() {
    this.bus.$on('showRickRoll', this.open)
  },
  methods: {
    async open() {
      this.$bvModal.show('rick-rolling')
    },
    clearSelection() {
      this.bus.$emit('clearCancellationSelection')
    },
  },
}
</script>
